.burger-menu {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
}

.burger {
  width: 30px;
  height: 3px;
  background-color: #fff;
  position: relative;
  transition: 0.25s ease;
}

.burger::before,
.burger::after {
  content: '';
  top: -10px;
  width: 100%;
  display: block;
  height: 3px;
  background-color: #fff;
  position: absolute;
  left: 0;
  border-radius: 2px;
  transition: 0.25s ease;
}

.burger::after {
  bottom: -10px;
  top: auto;
}

.burger.active::before {
  transform: rotate(45deg) translate(10px, 4px);
}

.burger.active::after {
  transform: rotate(-45deg) translate(10px, -4px);
}

.navbar-fixed .burger.active,
.burger.active {
  background-color: transparent;
}

.navbar-fixed .burger {
  background-color: #000;
}

.navbar-fixed .burger::before,
.navbar-fixed .burger::after {
  background-color: #000;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
}

.mobile-menu-link {
  display: block;
  padding: 12px;
  font-weight: 500;
  color: #333;
}

.mobile-menu-link.active {
  color: rgb(52, 106, 206);
}

.mobile-nav-container {
  position: fixed;
  width: 100%;
  top: 60px;
  height: calc(100vh - 60px);
  left: 0;
  display: flex;
  flex-direction: column;
}
