.feed-back {
  display: grid;
  grid-template-columns: repeat(1) 1fr;
  grid-gap: 20px;
}

input {
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 4px;
  border: 2px solid transparent;
}

input:active,
input:hover {
  border: 2px solid #fff;
  border-radius: 2px;
}

label {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}
