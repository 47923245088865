.list-item {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.list-item-img {
    padding: 13px;
    height: 250px;
    max-height: 250px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    padding: 12px;
}

.list-item-img img{
    max-width: 100%;
    height: auto;
}

.list-item h6 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    color: #484848;
    font-weight: 600;
    line-height: 20px;
    margin: 24px 0 18px 0;
}

.list-item p {
    margin: 0 0 16px 0;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: rgba(0, 0, 0, 0.5)
}

.list-item button {
    width: 85%;
}

@media (max-width: 992px) {
    .list-item-img {
        height: 140px;
        max-height: 140px;
    }

    .list-item h6{
        margin: 16px 0 10px 0;
        font-size: 14px;
    }

    .list-item p {
        font-size: 13px;
        margin: 0 0 12px 0;
    }
}

@media (max-width: 768px) {
    .list-item-img {
        height: 160px;
        max-height: 160px;
    }
}

@media (max-width: 576px) {
    
}