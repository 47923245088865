@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.screen-basic {
  min-height: 100vh;
}

.common-section {
  padding: 40px 0;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 1rem;
}

.product-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  padding: 10px;
}

.list-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-multiple-carousel__arrow {
  z-index: 20 !important;
  background: rgb(0 0 0 / 16%) !important;
}

.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8) !important;
}

.react-multi-carousel-dot button {
  border-color: #fff !important;
  background-color: transparent;
}

.react-multi-carousel-dot--active button {
  background-color: #fff !important;
}

@media (max-width: 1200px) {
  .container {
    max-width: 992px;
  }

  .product-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 700px;
  }

  .product-wrapper {
    grid-gap: 24px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 576px;
  }

  .product-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
  }

  .product-wrapper {
    padding: 15px;
  }

  .common-section {
    padding: 24px 0;
  }

  .mobile-padding {
    padding: 0 15px;
  }
}

.burger-menu {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
}

.burger {
  width: 30px;
  height: 3px;
  background-color: #fff;
  position: relative;
  transition: 0.25s ease;
}

.burger::before,
.burger::after {
  content: '';
  top: -10px;
  width: 100%;
  display: block;
  height: 3px;
  background-color: #fff;
  position: absolute;
  left: 0;
  border-radius: 2px;
  transition: 0.25s ease;
}

.burger::after {
  bottom: -10px;
  top: auto;
}

.burger.active::before {
  transform: rotate(45deg) translate(10px, 4px);
}

.burger.active::after {
  transform: rotate(-45deg) translate(10px, -4px);
}

.navbar-fixed .burger.active,
.burger.active {
  background-color: transparent;
}

.navbar-fixed .burger {
  background-color: #000;
}

.navbar-fixed .burger::before,
.navbar-fixed .burger::after {
  background-color: #000;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
}

.mobile-menu-link {
  display: block;
  padding: 12px;
  font-weight: 500;
  color: #333;
}

.mobile-menu-link.active {
  color: rgb(52, 106, 206);
}

.mobile-nav-container {
  position: fixed;
  width: 100%;
  top: 60px;
  height: calc(100vh - 60px);
  left: 0;
  display: flex;
  flex-direction: column;
}

.navbar {
    position: fixed;
    top:0;
    left:0;
    right: 0;
    z-index: 25;
    transition: all .4s ease;
}

.navbar-wrapper{
    display: flex;
    align-items: center;
    height: 72px;
}

.logo {
    display: block;
    width: 100%;
    max-width: 60px;
    min-width: 60px;
    padding: 8px;
    background-color: rgba(255,255,255, .7);
}

.logo img{
    width: 100%;
    height: auto;
}

.nav-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.nav-menu-item {
    margin: 0 15px;
}

.nav-menu-link {
    display: block;
    color: #fff;
    font-size: 15px;
    opacity: .8;
    padding: 8px 16px;
    position: relative;
    border: 1px solid transparent;
    line-height: 1;
    transition: .3s ease;
    border-radius: 4px;
    opacity: .7;
}

.nav-menu-link:hover,
.nav-menu-item.active .nav-menu-link {
    border-color:#fff;
    opacity: 1;
    transform: translateX(-2px);
}

.navbar-fixed {
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0,0,0, .2);
}

.navbar-fixed .nav-menu-link{
    color: #5FB6E7;
}

.navbar-fixed .nav-menu-link:hover,
.navbar-fixed .nav-menu-item.active .nav-menu-link {
    border-color: #5FB6E7;
}


@media (max-width: 992px) {
    .navbar-wrapper{
        justify-content: space-between;
        height: 60px;
    }

    .logo {
        max-width: 45px;
        min-width: 45px;
    }
}


/*Standard button*/
.btn {
    font-weight: 500;
    padding: 10px 16px;
    border: 3px solid transparent;
    background: none;
    font-size: 16px;
    transition: .2s ease;
    cursor: pointer;
}

/*White button*/
.btn-white {
    background-color: #fff;
    color: #000;
    border-color: #fff
}

/*White button on hover*/
.btn-outline-white:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

/*Header button on hover*/
.btn-white:hover {
    background: none;
    color: #fff;
    border-color: #fff;
} 

/*Transparent button*/
.btn-outline-white {
    border-color: #fff;
    color: #fff;
    background: none;
    cursor: pointer;
}


/* Button primary */
.btn-primary {
    color: rgba(95, 182, 231, 1);
    border-color: rgba(95, 182, 231, 1);
    background: none;
}


/* Button primary on hover */
.btn-primary:hover {
    color: #fff;
    background: rgba(95, 182, 231, 1);
    border-color: rgba(95, 182, 231, 1);
}

@media (max-width: 992px) {
   .btn {
    padding: 6px 12px;
    font-size: 14px;
    border-width: 1px;
   }
}

.home-wrapper {
    background-image: url(/static/media/home_bg.a44f0055.jpg);
    width: 100%;
    height: 100%;
    background-position: center, center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.home-wrapper::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(59, 73, 200, 0.6) 35.34%, rgba(8, 30, 228, 0) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
    top: 0;
    left: 0;
}

.home-wrapper .container {
    z-index: 4;
    position: relative;
}

.home-body {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.home-body h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
}

.home-body p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center; 
}

.home-body-inner {
    max-width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home-buttons {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px;
    margin-top: 16px;
}

.home-buttons button{
    min-width: 200px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 18px 24px;
}

@media (max-width: 1200px) {
    .home-body h1 {
        font-size: 36px;
      }
    
      .home-body p {
        font-size: 16px;
        margin-bottom: 0;
      }
    
      .home-buttons button {
        font-size: 16px;
        padding: 14px 24px;
      }
}

@media (max-width: 768px) {
    .home-body h1 {
        font-size: 34px;
        margin: 5px 0;
    }
}

@media (max-width: 576px) {

    .home-buttons {
        grid-template-columns: 1fr;
    }

    .home-buttons button {
        font-size: 12px;
        padding: 12px;
    }
    .home-body h1 {
        font-size: 30px;
    }
    .home-body p {
        font-size: 14px;
    }
}
.list-item {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.list-item-img {
    padding: 13px;
    height: 250px;
    max-height: 250px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    padding: 12px;
}

.list-item-img img{
    max-width: 100%;
    height: auto;
}

.list-item h6 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    color: #484848;
    font-weight: 600;
    line-height: 20px;
    margin: 24px 0 18px 0;
}

.list-item p {
    margin: 0 0 16px 0;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: rgba(0, 0, 0, 0.5)
}

.list-item button {
    width: 85%;
}

@media (max-width: 992px) {
    .list-item-img {
        height: 140px;
        max-height: 140px;
    }

    .list-item h6{
        margin: 16px 0 10px 0;
        font-size: 14px;
    }

    .list-item p {
        font-size: 13px;
        margin: 0 0 12px 0;
    }
}

@media (max-width: 768px) {
    .list-item-img {
        height: 160px;
        max-height: 160px;
    }
}

@media (max-width: 576px) {
    
}




.close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    background: none;
    border: beige;
}

.custom-modal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: #fff;
    border-radius: 8px;
    outline: none;
    overflow: hidden;
    max-height: calc(100vh - 48px);
    padding-bottom: 20px;
}

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
}

.custom-modal-body {
    padding: 24px;
    max-height: calc(100vh - 48px);
    width: 80vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-modal-body::-webkit-scrollbar {
    width: 6px;
}

.custom-modal-body::-webkit-scrollbar-thumb {
    background-color: #999;
}

.custom-modal-body img{
    max-width: 60%;
    height: auto;
}



.list-title {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin: 16px;
}

.list-info {
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin: 8px;
    font-family: 'Montserrat', sans-serif;
}

.list-image {
    display: flex;
    justify-content: center;
}

.list-image img{
    max-width: 100%;
    height: auto;
}

@media (max-width: 576px) {
    .list-title {
        font-size: 14px;
    }
}
.section-heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    text-align: center;
}

.reason-text {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #5FB6E7;
    letter-spacing: 1px;
}

.section-heading {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
    color: #3C3F41;
    margin: 20px 0 10px 0; 
}

.section-description {
    max-width: 450px;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin: 0;
    color: rgba(0,0,0, .5);
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 992px) {
    .section-description{
        font-size: 13px;
    }
    .section-heading {
        margin: 10px 0;
        font-size: 20px;
    }
    .reason-text {
        font-size: 10px;
    }
    .section-heading-wrapper {
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .section-heading {
        font-size: 16px;
        margin-top: 0;
    }
    .section-description {
        font-size: 10px;
    }
    .reason-text {
        font-size: 7px;
    }
}

.product-info {
    margin-bottom: 28px;
}

.product-info p span {
    font-weight: bold;
}

.product-info p {
    padding: 0;
    margin: 0;
}


.table-head {
    background: #4A4A49;
    color: #fff;
}

table, td, th {  
    border: 1px solid #ddd;
    text-align: center;
}

.description {
    text-align: left;
}

table {
border-collapse: collapse;
width: 100%;
}


.table-responsive {
    overflow-x: auto;
    overflow-y: hidden;
}


tr:nth-child(even) {background-color: #f2f2f2;}
.about-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.about-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100%/4);
    text-align: center;
    padding: 0 15px;
}

.about-item svg {
    width: 60px;
    height: 60px;
}

.about-item h6 {
    font-weight: 600;
    font-size: 16px;
    margin: 24px 0;
    color: #484848;
}

.about-item p{
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: rgba(0, 0, 0, .5);
    margin: 0;
    max-width: 85%;
}

@media (max-width: 1200px) {
    .about-item {
        width: calc(100%/3);
    }
}

@media (max-width: 992px) {
    .about-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .about-item{
        width: 80%;
        padding: 15px;
    }

    .about-item h6 {
        margin: 10px 0;
    }
}

@media (max-width: 576px) {
    .about-item h6 {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .about-item p {
        font-size: 10px;
    }
}

.partner-item {
    margin-bottom: 20px;
}

.partner-item {
    background-color: #fff;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partner-item img {
    min-width: 40%;
    max-width: 40%;
    height: auto;
}
.footer {
    background-color: rgba(39, 81, 108, 1);
    padding: 40px 0;
}

.footer-back {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

.footer-back div {
    text-align: left;
    font-size: 30px;
}

.footer-logo {
    min-width: 90px;
    max-width: 90px;
    display: flex;
    justify-content: center;
    background-color: rgba(255,255,255, .7);
    padding: 6px;
    border-radius: 5px;
    transition: .2s ease;
    margin-bottom: 30px;
    cursor: pointer;
}

.footer-logo:hover {
    box-shadow: 0 6px 12px rgb(255,255,255, .3 );
    border-radius: 5px;
}

.footer p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #AEBEC8;
    padding: 0;
}

h6 {
    color: #fff;
    margin: 0 0 15px 15px;
}

.menu {
    margin: auto 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.menu-link {
    display: block;
    color: #fff;
    font-size: 15px;
    padding: 8px 16px;
    line-height: 1;
    transition: .3s ease;
    border-radius: 4px;
    opacity: .7;
}

.menu-item {
    border-color:#fff;
    opacity: 1;
    transform: translateX(-2px);
}

.menu-link:hover,
.menu-item.active .menu-link {
    opacity: 1;
    transform: translateX(-2px);
}

.e-mail {
    width: 70%;
}

.e-mail h6 {
    margin: auto 0 0 0;
}

@media (max-width: 768px) {
    .menu-wrapper {
        display: none;
    }
    .footer-back {
      display: grid;
      grid-template-columns: 1fr 1fr;
    } 
}

@media (max-width: 576px) {
    .footer-back {
        grid-template-columns: 1fr;
    }

    .menu-wrapper {
        align-items: flex-start;
    }

    .footer{
        padding: 24px 0;
    }
}
.feed-back {
  display: grid;
  grid-template-columns: repeat(1) 1fr;
  grid-gap: 20px;
}

input {
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 4px;
  border: 2px solid transparent;
}

input:active,
input:hover {
  border: 2px solid #fff;
  border-radius: 2px;
}

label {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}



.slider-container{
    padding: 32px 0;
    width: 100%;
    background-image: url(/static/media/slide-bg.edc0a9a6.png);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
}

.slider-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgb(51 146 198 / 78%) 100%, rgba(3, 25, 226, 0) 100%);
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
}

.slider-wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-info {
    display: flex;
    flex-direction: column;
}

.slider-info h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-transform: uppercase;
}

.slider-info p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 35px;
    max-width: 75%;
}

.slider-btn {
    width: 45%;
}

.slider-img img {
    max-width: 300px;
    height: auto;
}

.reverse .slider-info {
    order: 2;
    text-align: right;
}

.reverse .slider-img {
    order: 1;
}

.reverse .slider-info {
    align-items: flex-end;
}



.slider-container{
    padding: 32px 0;
    width: 100%;
    background-image: url(/static/media/slide-bg.edc0a9a6.png);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
}

.slider-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgb(51 146 198 / 78%) 100%, rgba(3, 25, 226, 0) 100%);
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
}

.slider-wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-info {
    display: flex;
    flex-direction: column;
}

.slider-info h3 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-transform: uppercase;
}

.slider-info p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 35px;
    max-width: 75%;
}

.slider-btn {
    width: 45%;
}


.slider-img img {
    max-width: 300px;
    height: auto;
}

.reverse .slider-info {
    order: 2;
    text-align: right;
}

.reverse .slider-img {
    order: 1
}

.reverse .slider-info {
    align-items: flex-end;
}

@media (max-width: 992px) {
    .slider-wrapper {
        margin: 5px;
    }
    .slider-info h3 {
        font-size:24px;
    }
    .slider-info p {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .slider-info h3 {
        font-size: 20px;
        margin-bottom: 0;
    }

    .slider-info p {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .slider-info h3 {
        font-size: 16px;
    }
    .slider-wrapper {
        flex-direction: column;
        justify-content: unset;
        align-items: center;
        padding-bottom: 30px;
    }

    .reverse .slider-info, .slider-info {
        order: 2;
        align-items: center;
    }

    .reverse .slider-img, .slider-img {
        order: 1;
    }

    .slider-info p{
       max-width: 80%;
       text-align: center;
       margin: 5px 0;
    }
    
}


