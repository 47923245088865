
.partner-item {
    margin-bottom: 20px;
}

.partner-item {
    background-color: #fff;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partner-item img {
    min-width: 40%;
    max-width: 40%;
    height: auto;
}