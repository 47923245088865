.navbar {
    position: fixed;
    top:0;
    left:0;
    right: 0;
    z-index: 25;
    transition: all .4s ease;
}

.navbar-wrapper{
    display: flex;
    align-items: center;
    height: 72px;
}

.logo {
    display: block;
    width: 100%;
    max-width: 60px;
    min-width: 60px;
    padding: 8px;
    background-color: rgba(255,255,255, .7);
}

.logo img{
    width: 100%;
    height: auto;
}

.nav-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.nav-menu-item {
    margin: 0 15px;
}

.nav-menu-link {
    display: block;
    color: #fff;
    font-size: 15px;
    opacity: .8;
    padding: 8px 16px;
    position: relative;
    border: 1px solid transparent;
    line-height: 1;
    transition: .3s ease;
    border-radius: 4px;
    opacity: .7;
}

.nav-menu-link:hover,
.nav-menu-item.active .nav-menu-link {
    border-color:#fff;
    opacity: 1;
    transform: translateX(-2px);
}

.navbar-fixed {
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0,0,0, .2);
}

.navbar-fixed .nav-menu-link{
    color: #5FB6E7;
}

.navbar-fixed .nav-menu-link:hover,
.navbar-fixed .nav-menu-item.active .nav-menu-link {
    border-color: #5FB6E7;
}


@media (max-width: 992px) {
    .navbar-wrapper{
        justify-content: space-between;
        height: 60px;
    }

    .logo {
        max-width: 45px;
        min-width: 45px;
    }
}
