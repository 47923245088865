@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@import '~react-multi-carousel/lib/styles.css';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.screen-basic {
  min-height: 100vh;
}

.common-section {
  padding: 40px 0;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 1rem;
}

.product-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  padding: 10px;
}

.list-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-multiple-carousel__arrow {
  z-index: 20 !important;
  background: rgb(0 0 0 / 16%) !important;
}

.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8) !important;
}

.react-multi-carousel-dot button {
  border-color: #fff !important;
  background-color: transparent;
}

.react-multi-carousel-dot--active button {
  background-color: #fff !important;
}

@media (max-width: 1200px) {
  .container {
    max-width: 992px;
  }

  .product-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 700px;
  }

  .product-wrapper {
    grid-gap: 24px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 576px;
  }

  .product-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
  }

  .product-wrapper {
    padding: 15px;
  }

  .common-section {
    padding: 24px 0;
  }

  .mobile-padding {
    padding: 0 15px;
  }
}
