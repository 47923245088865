.home-wrapper {
    background-image: url("../../Assets/images/home_bg.jpg");
    width: 100%;
    height: 100%;
    background-position: center, center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.home-wrapper::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(59, 73, 200, 0.6) 35.34%, rgba(8, 30, 228, 0) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
    top: 0;
    left: 0;
}

.home-wrapper .container {
    z-index: 4;
    position: relative;
}

.home-body {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.home-body h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
}

.home-body p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center; 
}

.home-body-inner {
    max-width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home-buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px;
    margin-top: 16px;
}

.home-buttons button{
    min-width: 200px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 18px 24px;
}

@media (max-width: 1200px) {
    .home-body h1 {
        font-size: 36px;
      }
    
      .home-body p {
        font-size: 16px;
        margin-bottom: 0;
      }
    
      .home-buttons button {
        font-size: 16px;
        padding: 14px 24px;
      }
}

@media (max-width: 768px) {
    .home-body h1 {
        font-size: 34px;
        margin: 5px 0;
    }
}

@media (max-width: 576px) {

    .home-buttons {
        grid-template-columns: 1fr;
    }

    .home-buttons button {
        font-size: 12px;
        padding: 12px;
    }
    .home-body h1 {
        font-size: 30px;
    }
    .home-body p {
        font-size: 14px;
    }
}