
/*Standard button*/
.btn {
    font-weight: 500;
    padding: 10px 16px;
    border: 3px solid transparent;
    background: none;
    font-size: 16px;
    transition: .2s ease;
    cursor: pointer;
}

/*White button*/
.btn-white {
    background-color: #fff;
    color: #000;
    border-color: #fff
}

/*White button on hover*/
.btn-outline-white:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

/*Header button on hover*/
.btn-white:hover {
    background: none;
    color: #fff;
    border-color: #fff;
} 

/*Transparent button*/
.btn-outline-white {
    border-color: #fff;
    color: #fff;
    background: none;
    cursor: pointer;
}


/* Button primary */
.btn-primary {
    color: rgba(95, 182, 231, 1);
    border-color: rgba(95, 182, 231, 1);
    background: none;
}


/* Button primary on hover */
.btn-primary:hover {
    color: #fff;
    background: rgba(95, 182, 231, 1);
    border-color: rgba(95, 182, 231, 1);
}

@media (max-width: 992px) {
   .btn {
    padding: 6px 12px;
    font-size: 14px;
    border-width: 1px;
   }
}
