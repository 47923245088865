

.slider-container{
    padding: 32px 0;
    width: 100%;
    background-image: url(../../Assets/images/slide-bg.png);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
}

.slider-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgb(51 146 198 / 78%) 100%, rgba(3, 25, 226, 0) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
}

.slider-wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-info {
    display: flex;
    flex-direction: column;
}

.slider-info h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-transform: uppercase;
}

.slider-info p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 35px;
    max-width: 75%;
}

.slider-btn {
    width: 45%;
}

.slider-img img {
    max-width: 300px;
    height: auto;
}

.reverse .slider-info {
    order: 2;
    text-align: right;
}

.reverse .slider-img {
    order: 1;
}

.reverse .slider-info {
    align-items: flex-end;
}
