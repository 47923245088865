.section-heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    text-align: center;
}

.reason-text {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #5FB6E7;
    letter-spacing: 1px;
}

.section-heading {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
    color: #3C3F41;
    margin: 20px 0 10px 0; 
}

.section-description {
    max-width: 450px;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin: 0;
    color: rgba(0,0,0, .5);
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 992px) {
    .section-description{
        font-size: 13px;
    }
    .section-heading {
        margin: 10px 0;
        font-size: 20px;
    }
    .reason-text {
        font-size: 10px;
    }
    .section-heading-wrapper {
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .section-heading {
        font-size: 16px;
        margin-top: 0;
    }
    .section-description {
        font-size: 10px;
    }
    .reason-text {
        font-size: 7px;
    }
}