

.slider-container{
    padding: 32px 0;
    width: 100%;
    background-image: url(../../Assets/images/slide-bg.png);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
}

.slider-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgb(51 146 198 / 78%) 100%, rgba(3, 25, 226, 0) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
}

.slider-wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-info {
    display: flex;
    flex-direction: column;
}

.slider-info h3 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-transform: uppercase;
}

.slider-info p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 35px;
    max-width: 75%;
}

.slider-btn {
    width: 45%;
}


.slider-img img {
    max-width: 300px;
    height: auto;
}

.reverse .slider-info {
    order: 2;
    text-align: right;
}

.reverse .slider-img {
    order: 1
}

.reverse .slider-info {
    align-items: flex-end;
}

@media (max-width: 992px) {
    .slider-wrapper {
        margin: 5px;
    }
    .slider-info h3 {
        font-size:24px;
    }
    .slider-info p {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .slider-info h3 {
        font-size: 20px;
        margin-bottom: 0;
    }

    .slider-info p {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .slider-info h3 {
        font-size: 16px;
    }
    .slider-wrapper {
        flex-direction: column;
        justify-content: unset;
        align-items: center;
        padding-bottom: 30px;
    }

    .reverse .slider-info, .slider-info {
        order: 2;
        align-items: center;
    }

    .reverse .slider-img, .slider-img {
        order: 1;
    }

    .slider-info p{
       max-width: 80%;
       text-align: center;
       margin: 5px 0;
    }
    
}

