

.list-title {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin: 16px;
}

.list-info {
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin: 8px;
    font-family: 'Montserrat', sans-serif;
}

.list-image {
    display: flex;
    justify-content: center;
}

.list-image img{
    max-width: 100%;
    height: auto;
}

@media (max-width: 576px) {
    .list-title {
        font-size: 14px;
    }
}