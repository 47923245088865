@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

.footer {
    background-color: rgba(39, 81, 108, 1);
    padding: 40px 0;
}

.footer-back {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

.footer-back div {
    text-align: left;
    font-size: 30px;
}

.footer-logo {
    min-width: 90px;
    max-width: 90px;
    display: flex;
    justify-content: center;
    background-color: rgba(255,255,255, .7);
    padding: 6px;
    border-radius: 5px;
    transition: .2s ease;
    margin-bottom: 30px;
    cursor: pointer;
}

.footer-logo:hover {
    box-shadow: 0 6px 12px rgb(255,255,255, .3 );
    border-radius: 5px;
}

.footer p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #AEBEC8;
    padding: 0;
}

h6 {
    color: #fff;
    margin: 0 0 15px 15px;
}

.menu {
    margin: auto 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.menu-link {
    display: block;
    color: #fff;
    font-size: 15px;
    padding: 8px 16px;
    line-height: 1;
    transition: .3s ease;
    border-radius: 4px;
    opacity: .7;
}

.menu-item {
    border-color:#fff;
    opacity: 1;
    transform: translateX(-2px);
}

.menu-link:hover,
.menu-item.active .menu-link {
    opacity: 1;
    transform: translateX(-2px);
}

.e-mail {
    width: 70%;
}

.e-mail h6 {
    margin: auto 0 0 0;
}

@media (max-width: 768px) {
    .menu-wrapper {
        display: none;
    }
    .footer-back {
      display: grid;
      grid-template-columns: 1fr 1fr;
    } 
}

@media (max-width: 576px) {
    .footer-back {
        grid-template-columns: 1fr;
    }

    .menu-wrapper {
        align-items: flex-start;
    }

    .footer{
        padding: 24px 0;
    }
}