.about-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.about-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100%/4);
    text-align: center;
    padding: 0 15px;
}

.about-item svg {
    width: 60px;
    height: 60px;
}

.about-item h6 {
    font-weight: 600;
    font-size: 16px;
    margin: 24px 0;
    color: #484848;
}

.about-item p{
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    color: rgba(0, 0, 0, .5);
    margin: 0;
    max-width: 85%;
}

@media (max-width: 1200px) {
    .about-item {
        width: calc(100%/3);
    }
}

@media (max-width: 992px) {
    .about-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .about-item{
        width: 80%;
        padding: 15px;
    }

    .about-item h6 {
        margin: 10px 0;
    }
}

@media (max-width: 576px) {
    .about-item h6 {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .about-item p {
        font-size: 10px;
    }
}